import React from 'react'
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { ShortUrlPaged } from '../../graphql/graphql';
import ShortUrlResult from '../ShortUrlResult';
import { BACKEND_HOST } from '../../backend';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';

function ShortUrlTableHead() {
    return <TableHead>
        <TableRow>
            <TableCell>
                Name
            </TableCell>
            <TableCell>
                Short Url
            </TableCell>
            <TableCell>
                Url
            </TableCell>
            <TableCell>
                Views
            </TableCell>
            <TableCell>
                Created On
            </TableCell>
            <TableCell />
        </TableRow>
    </TableHead>
}

type Props = {
    shortUrlPaged: ShortUrlPaged,
    handleOffsetChange: (offset: number) => void,
    handleLimitchange: (limit: number) => void,
}

function ShortUrlTable(props: Props) {

    const navigate = useNavigate()

    return <><TableContainer
        sx={{
            width: '100%',
            overflowX: 'auto',
            position: 'relative',
            display: 'block',
            maxWidth: '100%',
            '& td, & th': { whiteSpace: 'nowrap' }
        }}
    >
        <Table>
            <ShortUrlTableHead />
            <TableBody>
                {props.shortUrlPaged.data.map(shortUrl => <TableRow key={shortUrl.id}>
                    <TableCell>{shortUrl.name}</TableCell>
                    <TableCell>
                        <Formik initialValues={{}} onSubmit={() => { }}>{({ submitForm, isSubmitting }) => <Form>
                            <ShortUrlResult shortUrl={`${BACKEND_HOST}/${shortUrl.id}`} />
                        </Form>
                        }
                        </Formik>
                    </TableCell>
                    <TableCell>{shortUrl.url}</TableCell>
                    <TableCell>{shortUrl.numberOfClicks}</TableCell>
                    { /* todo createdTime will need to be formatted. */}
                    <TableCell>{Intl.DateTimeFormat("en-US", { dateStyle: 'short', timeStyle: 'short' })
                        .format(shortUrl.createdTime)}
                    </TableCell>
                    <TableCell>
                        <IconButton onClick={() => navigate(`${shortUrl.id}/views`)}>
                            <VisibilityIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>)
                }
            </TableBody>
        </Table>
    </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.shortUrlPaged.total}
            rowsPerPage={props.shortUrlPaged.limit || 10}
            page={props.shortUrlPaged.offset}
            onPageChange={(e, offset) => props.handleOffsetChange(offset)}
            onRowsPerPageChange={(e) => props.handleLimitchange(+e.target.value)}
        />
    </>
}

export default ShortUrlTable