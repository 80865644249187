import axios from "axios";

const env = window._env_ ? window._env_ : {}

export const BACKEND_HOST = env.BASE_URL || 'http://localhost:8080'

export const FIREBASE_API_KEY = env.FIREBASE_API_KEY

export const FIREBASE_AUTH_DOMAIN = env.FIREBASE_AUTH_DOMAIN

export const FIREBASE_PROJECT_ID = env.FIREBASE_PROJECT_ID

export const FIREBASE_STORAGE_BUCKET = env.FIREBASE_STORAGE_BUCKET

export const FIREBASE_MESSAGING_SENDER = env.FIREBASE_MESSAGING_SENDER

export const FIREBASE_APP_ID = env.FIREBASE_APP_ID

export const FIREBASE_MEASUREMENT_ID = env.FIREBASE_MEASUREMENT_ID


export const client = axios.create({
    baseURL: BACKEND_HOST,
    timeout: 1000,
    // headers: {'X-Custom-Header': 'custom header.'}
  })
