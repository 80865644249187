import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ReactComponent as Logo } from '../assets/vector/logo.svg'
import AccountActions from './AccountActions'

const ResponsiveAppBar = () => {

  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo style={{ height: '35px', width: '35px' }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              fontFamily: 'hk-grotesk',
              paddingLeft: '12px',
              fontWeight: 1000,
              letterSpacing: '.3rem',
              textDecoration: 'none',
              color: '#ffe1e1',
            }}
          >
            dourl
          </Typography>
          <AccountActions/>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;