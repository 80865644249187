import { Button, Dialog, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { gql, useMutation } from '@apollo/client'
import { CreateShortUrl } from '../graphql/graphql'

type Props = {
    open: boolean,
    toggleOpen: () => void,
    onNew: () => void,
}

const CREATE_SHORTURL_MUTATION = gql(`
    mutation CREATE_SHORTURL_MUTATION($create: CreateShortUrl) {
        createShortUrl(create: $create) {
            id
            name
            numberOfClicks
            createdTime
            url
        }
    }
`)

function NewShortUrlDialog(props: Props) {

    const [createShortUrl] = useMutation(CREATE_SHORTURL_MUTATION)

    const [create, setCreateState] = useState<CreateShortUrl>({
        url: '',
    })

    const onSubmit = () => {
        createShortUrl({ variables: { create: create } })
            .then((resp) => {
                props.onNew()
                setCreateState({ url: '', })
                props.toggleOpen()
            }).catch((err) => console.error(err))
    }

    return <Dialog open={props.open}>
        <DialogTitle>
            Create new Short Url
        </DialogTitle>
        <IconButton
            aria-label="close"
            onClick={props.toggleOpen}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Name"
                        value={create.name || ''}
                        onChange={(e) => {
                            setCreateState({ ...create, name: e.target.value })
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Url"
                        value={create.url}
                        onChange={(e) => {
                            setCreateState({ ...create, url: e.target.value })
                        }}
                    />
                </Grid>
                <Grid xs={12} display="flex" justifyContent="right" item>
                    <Button onClick={onSubmit}>Submit</Button>
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
}

export default NewShortUrlDialog