import { User, signOut } from 'firebase/auth'
import React from 'react'
import { firebaseApp, firebaseAuth, getCurrentUser } from '../firebase'

/**
 * Undefined - not yet loaded
 * null - not signed in. 
 * 
 * todo if the user is not loaded, the signout function is also not loaded correctly.
 * this might be an issue in the future...
 */
export const UserContext = React.createContext<[User | undefined | null, () => void]>([undefined, () => {}])

type Props = {
    children: string | JSX.Element | JSX.Element[],
}

export default function SignedInUserProvider(props: Props) {
    const [ userState, setUserState ] = React.useState<User | null>()

    const signOutFn = async () => {
        await firebaseAuth.signOut()
        setUserState(null)
    } 

    getCurrentUser().then((user) => setUserState(user))

    return <UserContext.Provider value={[userState, signOutFn]}>
        {props.children}
    </UserContext.Provider>
}