import React from 'react'
import { Button, Typography } from '@mui/material'
import { ReactComponent as GoogleIcon } from '../assets/vector/googleicon.svg'
import { signInWithPopup, GoogleAuthProvider, User } from "firebase/auth";
import { firebaseAuth, firebaseProvider } from '../firebase'


function GoogleSignInButton(props: { onSuccessCallback: (user: User) => void }) {
    return <Button
        variant="outlined"
        color="neutral"
        sx={{
            // width: 10,
        }}
        onClick={() => signInWithPopup(firebaseAuth, firebaseProvider).then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const user = result.user;
            props.onSuccessCallback(user)
            return user
        }).catch((error) => {
            // TODO better Error handlers.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        })}
    >
        <GoogleIcon style={{ height: '35px', width: '35px' }} />
        <Typography pl={2}>Sign in with Google</Typography>
    </Button>
}

export default GoogleSignInButton