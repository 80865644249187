import { createTheme } from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
    }

    interface PaletteOptions {
        neutral: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}

export const lightTheme = createTheme({
    components: {
        // MuiCard: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: '#1a1f25',
        //         }
        //     }
        // }
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#7a08fa',
        },
        secondary: {
            main: '#fc7eb6',
        },
        // neutral: {
        //     main: '#ddcbd3',
        // }
        
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    }
})

export const darkTheme = createTheme({
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1a1f25',
                }
            }
        }
    },
    palette: {
        mode: 'dark',
        primary: {
            // main: '#371141',
            // main: '#7a08fa',
            main: '#fc7eb6',
        },
        secondary: {
            // main: '#ca3782',
            main: '#7a08fa',
        },
        // neutral: {
        //     main: '#ddcbd3',
        // }
        
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
    }
})