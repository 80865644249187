import { setContext } from '@apollo/client/link/context'
import { getCurrentUser } from './firebase'

// Middleware for getting access token from local storage.

export const authMiddleware = setContext(() => {
    return getCurrentUser().then(user => user?.getIdTokenResult()).then((token) => {
        if (token) {
            return { headers: { authorization: `Bearer ${token.token}` } }
        }
        return {}
    })
})