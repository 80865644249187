import { gql, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import ShortUrlViewTable from '../components/tables/ShortUrlViewTable'
import { RegionCodesCount, ShortUrlViewPaged, UserAgentBrowserCount } from '../graphql/graphql'
import { Card, CardContent, CardHeader, Grid, Paper, Typography } from '@mui/material'
import ShortUrlRegionsBarChart from '../components/charts/ShortUrlRegionsBarChart'
import BrowserUsagePieChart from '../components/charts/BrowserUsagePieChart'
import shareLinks from '../assets/share-laptop.png'

const GET_VIEWS_QUERY = gql(`
    query GET_VIEWS_QUERY($shortUrlId: ID!, $limit: Int!, $offset: Int!) {
        getShortUrlViews(id: $shortUrlId, limit: $limit, offset: $offset) {
            total
            limit
            offset
            data {
                id
                localAddr
                localName
                remoteAddr
                remoteHost
                iso3Country
                iso3Language
                userAgent
                createdTime
                geoLocationUpdatedTime
                userAgentFamily
                userAgentMajor
                userAgentMinor
                osFamily
                osMajor
                osMinor
                userAgentFamily
                geoIpResult {
                    city
                    regionName
                    regionCode
                    postalCode
                    country
                    latitude
                    longitude
                }
            }
        }

        getShortUrlRegions(id: $shortUrlId, limit: 10) {
            regionCode
            regionName
            count
        }

        getBrowserCount(id: $shortUrlId, limit: 10) {
            userAgentFamily
            count
        }
    }
`)

function ShareUrlForViewsGrid() {
    return <Grid justifyContent="center" container>
        <Grid justifyContent="center" display="flex" item xs={12}>
            <Typography variant="h2">
                Share your link to get views!
            </Typography>
        </Grid>
        <Grid item display="flex" justifyContent="center">
            <img style={{ height: '400px', width: '400px'}} src={shareLinks} alt="Share Links" />
        </Grid>
    </Grid>
}

function ShortUrlViewPage() {

    const [limit, setLimit] = useState(10)

    const [offset, setOffset] = useState(0)

    const { shortUrlId } = useParams()

    const { data, loading, error } = useQuery(GET_VIEWS_QUERY, { variables: { limit: limit, offset: offset, shortUrlId } })

    if (data && (data['getShortUrlViews'] as ShortUrlViewPaged).data.length === 0) {
        return <Paper>
            <ShareUrlForViewsGrid/>
        </Paper>
    }

    return data && <Paper>
        <Grid justifyContent="center" container pt={4} spacing={8}>
            <Grid item>
                <Card sx={{ height: '420px' }}>
                    <CardHeader title="Top Regions" />
                    <CardContent>
                        <ShortUrlRegionsBarChart shortUrlRegions={data['getShortUrlRegions'] as Array<RegionCodesCount>} />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item>
                <Card sx={{ height: '420px' }}>
                    <CardHeader title="Top Browsers" />
                    <CardContent>
                        <BrowserUsagePieChart browserCount={data['getBrowserCount'] as Array<UserAgentBrowserCount>} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Grid xs={12} pt={4} p={2} item>
            <Card>
                <CardContent>
                    <ShortUrlViewTable
                        shortUrlViewPaged={data['getShortUrlViews'] as ShortUrlViewPaged}
                        handleLimitChange={setLimit}
                        handleOffsetChange={setOffset}
                    />
                </CardContent>
            </Card>
        </Grid>
    </Paper>
}

export default ShortUrlViewPage