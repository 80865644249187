import React from 'react'
import { UserAgentBrowserCount } from '../../graphql/graphql'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { useTheme } from '@mui/material'

type Props = {
    browserCount: Array<UserAgentBrowserCount>
}

function BrowserUsagePieChart(props: Props) {

    const theme = useTheme()

    const options: ApexOptions = {
        labels: props.browserCount.map(browserCount => browserCount.userAgentFamily || 'Other'),
        theme: {
            mode: theme.palette.mode,
            palette: '#fc7eb6',
        },
        chart: { 
            background: 'transparent' 
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200,
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }


    return <div id="chart">
        <ReactApexChart options={options} series={props.browserCount.map((browserCount => browserCount.count))} type="pie" width={420} />
    </div>

}

export default BrowserUsagePieChart