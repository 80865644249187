import React from 'react'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import { Field } from 'formik'
import { TextField, Button, Grid, Tooltip, ClickAwayListener } from '@mui/material'

function ShortUrlResult({ shortUrl }) {

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    }

    const handleOnClick = () => {
        if (shortUrl) {
            setOpen(true)
            // todo set value from TextField.
            navigator.clipboard.writeText(shortUrl);
        }
    }

    return (<Grid container>
        <Grid item>
            <Field
                sx={{ width: '30ch' }}
                component={TextField}
                disabled
                value={shortUrl || '...'}
            />
        </Grid>
        <Grid pl={1} item display="flex">
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Copied to clipboard!"
                >
                    <Button disabled={!shortUrl} variant="outlined" onClick={handleOnClick}>
                        <ContentPasteIcon />
                    </Button>
                </Tooltip>
            </ClickAwayListener>
        </Grid>
    </Grid>)
}

export default ShortUrlResult