import React from 'react'
import { ShortUrlView, ShortUrlViewPaged } from '../../graphql/graphql'
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material'

type Props = {
    shortUrlViewPaged: ShortUrlViewPaged,
    handleOffsetChange: (offset: number) => void,
    handleLimitChange: (limit: number) => void,
}

function ShortUrlViewTableHead() {
    return <TableHead>
        <TableRow>
            <TableCell>
                Region
            </TableCell>
            <TableCell>
                Viewed On
            </TableCell>
            <TableCell>
                Language
            </TableCell>
            <TableCell>
                Viewed on
            </TableCell>
        </TableRow>
    </TableHead>
}

function ShortUrlViewTable(props: Props) {
    return <>
        <TableContainer
            sx={{
                width: '100%',
                overflowX: 'auto',
                position: 'relative',
                display: 'block',
                maxWidth: '100%',
                '& td, & th': { whiteSpace: 'nowrap' }
            }}
        >
            <Table>
                <ShortUrlViewTableHead />
                <TableBody>
                    {props.shortUrlViewPaged.data.map(shortUrlView => <TableRow key={shortUrlView.id}>
                        <TableCell>{shortUrlView.geoIpResult?.city} {shortUrlView.geoIpResult?.regionName} {shortUrlView.iso3Country}</TableCell>
                        <TableCell>{shortUrlView.userAgentFamily} {shortUrlView.osFamily}</TableCell>
                        <TableCell>{shortUrlView.iso3Language}</TableCell>
                        <TableCell>{Intl.DateTimeFormat("en-US", { dateStyle: 'short', timeStyle: 'short' })
                            .format(shortUrlView.createdTime)}</TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={props.shortUrlViewPaged.total}
            rowsPerPage={props.shortUrlViewPaged.limit || 10}
            page={props.shortUrlViewPaged.offset}
            onPageChange={(e, offset) => props.handleOffsetChange(offset)}
            onRowsPerPageChange={(e) => props.handleLimitChange(+e.target.value)}
        />
    </>
}

export default ShortUrlViewTable