import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, User } from "firebase/auth";
import { FIREBASE_API_KEY, FIREBASE_APP_ID, FIREBASE_AUTH_DOMAIN, FIREBASE_MEASUREMENT_ID, FIREBASE_MESSAGING_SENDER, FIREBASE_PROJECT_ID, FIREBASE_STORAGE_BUCKET } from "./backend";

export const firebaseApp = initializeApp({
   apiKey: FIREBASE_API_KEY,
   authDomain: FIREBASE_AUTH_DOMAIN,
   projectId: FIREBASE_PROJECT_ID,
   storageBucket: FIREBASE_STORAGE_BUCKET,
   messagingSenderId: FIREBASE_MESSAGING_SENDER,
   appId: FIREBASE_APP_ID,
   measurementId: FIREBASE_MEASUREMENT_ID,
})

export const firebaseAuth = getAuth(firebaseApp)

export const firebaseProvider = new GoogleAuthProvider();

// documented in https://github.com/firebase/firebase-js-sdk/issues/462
export function getCurrentUser(): Promise<User | null> {
   return new Promise((resolve, reject) => {
      const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
         unsubscribe();
         resolve(user);
      }, reject);
   });
}