import React, { useEffect } from 'react'

import { ThemeProvider } from '@mui/material/styles'
import { darkTheme, lightTheme } from './theme/theme'
import ResponsiveAppBar from './components/ResponsiveAppBar'
import CreateLinkPage from './components/CreateLinkPage'
import { CssBaseline, createTheme } from '@mui/material'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import ShortUrlPage from './pages/ShortUrlPage'
import SignIn from './components/SignIn'
import SignedInUserProvider from './provider/SignedInUserProvider'

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, concat, gql, useMutation, useLazyQuery } from '@apollo/client'
import { authMiddleware } from './ApolloMiddleware'
import ShortUrlViewPage from './pages/ShortUrlViewPage'
import { BACKEND_HOST } from './backend'

const httpLink = new HttpLink({ uri: `${BACKEND_HOST}/api/graphql` });

const client = new ApolloClient({

  uri: `${BACKEND_HOST}/api/graphql`,
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

const UPDATE_USER_THEME_MUTATION = gql(`
  mutation UPDATE_USER_THEME_MUTATION($mode: String!) {
    updateUserThemeMode(mode: $mode) {
      themeMode
    }
  }
`)

const GET_USER_QUERY = gql(`
    query GET_USER_QUERY {
        getUser {
            name
            themeMode
        }
    }`)

export const ColorModeContext = React.createContext()

function DoUrlApp() {

  const [mode, setMode] = React.useState('light');

  const [updateUserThemeMode] = useMutation(UPDATE_USER_THEME_MUTATION)

  const [getUser, { data, loading, error }] = useLazyQuery(GET_USER_QUERY)

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {

        setMode((prevMode) => {
          const themeMode = (prevMode === 'light' ? 'dark' : 'light')
          updateUserThemeMode({ variables: { mode: themeMode } })
          return themeMode
        });
      },
      setColorMode: (it) => { console.log('Updating color mode', it); setMode((prev) => it) }
    }),
    [],
  );

  useEffect(() => {
    getUser().then((user) => {
      if (user.data && user.data['getUser']) {
        colorMode.setColorMode(user?.data['getUser'].themeMode)
      }
    })
  }, [colorMode, getUser])

  const theme = React.useMemo(() => createTheme(mode === 'dark' ? darkTheme : lightTheme), [mode])

  return <ColorModeContext.Provider value={colorMode}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ResponsiveAppBar />
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/shorturl" element={<ShortUrlPage />} />
        <Route path="/shorturl/:shortUrlId/views" element={<ShortUrlViewPage />} />
        <Route path="*" element={<CreateLinkPage />} />
      </Routes>
    </ThemeProvider>
  </ColorModeContext.Provider>
}

function App() {

  return <BrowserRouter basename='/ui'>
    <ApolloProvider client={client}>
      <SignedInUserProvider>
        <DoUrlApp />
      </SignedInUserProvider>
    </ApolloProvider>
  </BrowserRouter>
}

export default App;
