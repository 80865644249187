import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'

import ReactApexChart from 'react-apexcharts';
import { RegionCodesCount } from '../../graphql/graphql';
import { ApexOptions } from 'apexcharts';


type Props = {
    shortUrlRegions: Array<RegionCodesCount>,
}

function ShortUrlRegionsBarChart(props: Props) {
    const theme = useTheme();

    const { primary, secondary } = theme.palette.text;
    const info = theme.palette.info.light;

    const [series] = useState([
        {
            name: '',
            data: props.shortUrlRegions.map(region => region.count)
        }
    ]);

    const map = React.useMemo(() => new Map<string, string>(props.shortUrlRegions.map(obj => [obj.regionCode, obj.regionName || ''])), [props]);

    const [options, setOptions] = useState<ApexOptions>({
        chart: {
            type: 'bar',
            background: 'transparent',
            toolbar: {
                show: false
            }
        },
        colors: ['#fd6a6a'],
        theme: {
            mode: theme.palette.mode,
            palette: '#fc7eb6',
        },
        tooltip: {
            x: {
                formatter: (val: number, opts?: any) => map.get(val.toString()) || ''
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '45%',
                borderRadius: 4
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: props.shortUrlRegions.map(region => region.regionCode),

            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false
        },
        grid: {
            show: false
        }
    });

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="bar" width={420} height={315} />
        </div>
    );
}

export default ShortUrlRegionsBarChart