import React from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { Paper, TextField, Typography, Grid, Button } from '@mui/material'
import { Formik, Form, Field } from 'formik'
import ShortUrlResult from './ShortUrlResult'
import { Box } from '@mui/system'
import { BACKEND_HOST, client } from '../backend'

function Heading() {
    return <Typography variant="h4" align="center">Create a sharable short link </Typography>
}
function CreateLinkPage() {

    const [shortUrl, setShortUrl] = React.useState(undefined)

    const [longUrl, setLongUrl] = React.useState(undefined)

    const [error, setError] = React.useState(undefined)

    return <Paper elevation={3}>
        <Box pb={3} pt={5}>
            <Heading />
        </Box>
        <Formik
            initialValues={{}}
            onSubmit={(values, { setSubmitting }) => {
                if (!shortUrl) {
                    client.post('/shorturl', { url: longUrl }, { headers: { 'Content-Type': 'application/json' } })
                        .then((response) => {
                            setError(undefined)
                            setSubmitting(false)
                            setShortUrl(`${BACKEND_HOST}/${response.data.id}`)
                        })
                        .catch((err) => {
                            setSubmitting(false)
                            setError(err.code === "ERR_BAD_REQUEST" ? 'Please enter a valid url' : 'Error please try again later.')
                        })
                }
            }}
        >
            {({ submitForm, isSubmitting }) => (<Form>
                <Box pb={3} pt={3} sx={{ height: '100vh' }}>
                    <Grid justifyContent="center" container spacing={1}>
                        <Grid item>
                            <Field
                                sx={{ width: '30ch' }}
                                component={TextField}
                                error={error}
                                helperText="Enter Url to share"
                                label={error || "Url"}
                                defaultValue={longUrl}
                                onChange={(e) => setLongUrl(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Button disabled={shortUrl} variant="outlined" type="submit">
                                <ArrowRightAltIcon onClick={submitForm} />
                            </Button>
                        </Grid>
                        <Grid item>
                            <ShortUrlResult shortUrl={shortUrl} />
                        </Grid>
                    </Grid>
                </Box>
            </Form>)
            }
        </Formik>
    </Paper>
}

export default CreateLinkPage