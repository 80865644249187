import React from 'react'
import { ShortUrlPaged } from '../graphql/graphql'
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import ShortUrlTable from '../components/tables/ShortUrlTable';
import Add from '@mui/icons-material/Add';
import womanWorkingImage from '../assets/woman-working.png'

type Props = {
    shortUrlPaged: ShortUrlPaged,
    handleLimitChange: (limit: number) => void,
    handleOffsetChange: (offset: number) => void,
    createNewClick: () => void,
}

function ShortUrlTableCard(props: Props) {
    const createFirstLink = props.shortUrlPaged.data.length === 0
    return <Card>
        <CardHeader
            action={
                (!createFirstLink) && <Button variant="contained" startIcon={<Add />} onClick={props.createNewClick}>
                    Create New
                </Button>
            }
        />
        <CardContent>
            {createFirstLink ? <Box>
                <Box display="flex" justifyContent="center">
                    <Button variant="outlined" onClick={props.createNewClick}>
                        Create Link
                    </Button>
                </Box>
                <Box display="flex" justifyContent="center">
                    <img style={{ height: '400px', width: '400px' }} src={womanWorkingImage} alt="Create New" />
                </Box>
            </Box> :
                <ShortUrlTable
                    handleLimitchange={props.handleLimitChange}
                    handleOffsetChange={props.handleOffsetChange}
                    shortUrlPaged={props.shortUrlPaged} />
            }
        </CardContent>
    </Card>
}

export default ShortUrlTableCard