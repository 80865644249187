import { Grid, Paper, Typography } from '@mui/material';
import ShortUrlTable from '../components/tables/ShortUrlTable';
import { gql, useQuery } from '@apollo/client'
import { ShortUrlPaged } from '../graphql/graphql';
import { useState } from 'react';
import NewShortUrlDialog from '../components/NewShortUrlDialog';
import ShortUrlTableCard from '../components/ShortUrlTableCard';


const GET_QUERY = gql(`
    query GET_QUERY($limit: Int!, $offset: Int!) {
        getShortUrl(limit: $limit, offset: $offset) {
            total
            limit
            offset
            data {
                id
                name
                numberOfClicks
                createdTime
                url
            }
        }
    }
`)

function ShortUrlPage() {

    const [limit, setLimit] = useState(10)

    const [offset, setOffset] = useState(0)

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { limit: limit, offset: offset } })

    const [modalOpen, setModalOpen] = useState(false)

    return <Paper>
        <Grid container>
            <Grid item xs={12} p={4}>
                <Typography align="center" variant="h5">Your Urls</Typography>
            </Grid>
            <Grid item xs={12}>
                {data &&
                    <ShortUrlTableCard
                        shortUrlPaged={data['getShortUrl'] as ShortUrlPaged}
                        handleLimitChange={setLimit}
                        handleOffsetChange={setOffset}
                        createNewClick={() => setModalOpen(true)}
                    />
                }
                <NewShortUrlDialog onNew={() => refetch()} open={modalOpen} toggleOpen={() => setModalOpen(!modalOpen)} />
            </Grid>
        </Grid>
    </Paper>
}

export default ShortUrlPage

